/**
 * @constant ENDPOINTS
 * @description endpoint collection in key value pair
 */
export const ENDPOINTS = {
  CRUD_ACTIVITY: '/internal/api/v1/activity',
  CRUD_AGENT_COMPONENT: '/internal/api/v1/agent-home/component',
  CRUD_AGENT_MENU: '/internal/api/v1/agent-home/menu',
  CRUD_AREA: '/internal/api/v1/area',
  CRUD_CASH_REWARD: '/internal/api/v1/sales/cash-reward',
  CRUD_CATEGORY: '/internal/api/v1/news-event-category',
  CRUD_CHANNEL_EVENT: '/internal/api/v1/channel-event',
  CRUD_CHANNEL: '/internal/api/v1/channel',
  CRUD_CITY_GROUP: '/internal/api/v1/city-group',
  CRUD_CITY: '/internal/api/v1/city',
  CRUD_COMMISSION: '/internal/api/v1/sales/commission',
  CRUD_COMPANY: '/internal/api/v1/company',
  CRUD_CUSTOMER_COMPONENT: '/internal/api/v1/customer-home/component',
  CRUD_CUSTOMER_HISTORY: '/internal/api/v1/customer/history',
  CRUD_CUSTOMER_WA_CHAT_HISTORY: '/internal/api/v1/customer/wa-chat-history',
  CRUD_CUSTOMER_MENU: '/internal/api/v1/customer-home/menu',
  CRUD_CUSTOMER: '/internal/api/v1/customer/data',
  POST_CUSTOMER_AGENT_MIGRATION: '/internal/api/v1/customer/agent-migration',
  BLAST_CUSTOMER_WA: '/internal/api/v1/whatsapp/blast-customer',
  GET_INVESTOR_CUSTOMER_LIST: '/internal/api/v1/customer/investor/list',
  CRUD_DEVELOPER: '/internal/api/v1/developer',
  CRUD_TEAM_INTOOLS: '/internal/api/v1/team',
  CRUD_TEAM_MEMBER: '/internal/api/v1/team-member',
  CRUD_TEAM: '/internal/api/v1/teams',
  CRUD_INVESTOR_TEAM: '/internal/api/v1/investor/teams',
  CRUD_DISTRICT: '/internal/api/v1/district',
  CRUD_INTERNAL_USER: '/internal/api/v1/internal-user',
  CREATE_INVESTOR_WHITELIST_ITEM: '/internal/api/v1/internal-user/investor/add-whitelist',
  GET_INVESTOR_WHITELIST_PRODUCT: '/internal/api/v1/internal-user/investor/whitelist-product',
  GET_INVESTOR_WHITELIST_TEAM: '/internal/api/v1/internal-user/investor/whitelist-team',
  CRUD_LISTING: '/internal/api/v1/listing',
  CRUD_NEWS_EVENT: '/internal/api/v1/news-event',
  CRUD_NON_CASH_REWARD: '/internal/api/v1/sales/non-cash-reward',
  CRUD_PRESERVE_ADVANTAGE: '/internal/api/v1/preserve-advantage',
  CRUD_PRODUCT: '/internal/api/v1/product',
  CRUD_ADS_PROJECT: '/internal/api/v1/ads-project',
  CRUD_ADS_PROJECT_BUDGET: '/internal/api/v1/ads-project-budget',
  CRUD_ADS_PROJECT_BUDGET_BY_PROJECT: '/internal/api/v1/ads-project-budget/ads-project',
  CRUD_ADS_PROJECT_BUDGET_CALCULATION: '/internal/api/v1/ads-project-budget/calculate',
  CRUD_INVESTOR_PRODUCT: '/internal/api/v1/investor/product',
  CRUD_PRODUCT_UNIT: '/internal/api/v1/product-unit/list',
  UPDATE_UNIT_FULL: '/internal/api/v1/product/unit/update-with-prices',
  GET_PRODUCT_UNIT_FULL: '/internal/api/v1/product/unit/get-with-prices',
  GET_PRODUCT_UNIT_LIST: '/internal/api/v1/product-unit/list',
  GET_SALES_REPORT: '/internal/api/v1/report/sales',
  CRUD_PROJECT_NUP: '/internal/api/v1/nup/project',
  CRUD_PROJECT: '/internal/api/v1/project',
  CRUD_PROPERTY_GROUP: '/internal/api/v1/property-group',
  CRUD_PROPERTY_TYPE: '/internal/api/v1/property-type',
  CRUD_PROVINCE: '/internal/api/v1/province',
  CRUD_SALES_MATERIAL_DETAIL: '/internal/api/v1/sales-material/detail',
  CRUD_SALES_MATERIAL_GROUP: '/internal/api/v1/sales-material/group',
  CRUD_SALES_MATERIAL_MASTER: '/internal/api/v1/sales-material/master',
  CRUD_SALES_TRANSACTION: '/internal/api/v1/sales/transaction',
  CRUD_STANDARD_USER: '/internal/api/v1/standard-user',
  CRUD_SUB_TYPE: '/internal/api/v1/sub-type',
  CRUD_TAG: '/internal/api/v1/tag',
  CRUD_TEMPLATE: '/internal/api/v1/template',
  CRUD_TRANSACTION_NUP: '/internal/api/v1/nup/tr',
  FILE_UPLOAD: '/file',
  GET_INTERNAL_USER_INFO: '/internal/api/v1/internal-user/info',
  GET_PRODUCT_TYPE: '/internal/api/v1/product-type',
  GET_UNIT_WITH_PRICE: '/internal/api/v1/product/unit/get-with-prices',
  GET_COUNTER_BY_ID: '/internal/api/v1/counter',
  GET_COUNTER_LIST: '/internal/api/v1/launching/nup-queue',
  GET_COUNTER_NUP_PRODUCT_UNIT: '/internal/api/v1/launching/counter/product-unit',
  GET_COUNTER_NUP_QUEUE_LIST: '/internal/api/v1/launching/counter/nup-queue',
  GET_TRANSACTION_NUP: '/internal/api/v1/nup/tr',
  POST_PROCESS_NUP: '/internal/api/v1/launching/process-nup',
  POST_CANCEL_NUP: '/internal/api/v1/launching/cancel-nup-process',
  POST_SKIP_NUP: '/internal/api/v1/launching/skip-nup-process',
  IS_AUTHENTICATE: '/internal/api/v1/internal-user/is-authenticate',
  LOGIN: '/internal/api/v1/internal-user/login',
  LOGOUT: '/internal/api/v1/internal-user/logout',
  POST_FINSIH_COUNTER_PROCESS: '/internal/api/v1/launching/finish-counter-process',
  UPSERT_UNIT_BULK_FILE: '/internal/api/v1/product/unit/upsert/bulk',
};
